<template lang="pug">
    client-only
        .ui-desktop-mobile-image
            img(:src="require(`../../assets/images/service/desktop-mobile-images/${imageName}/${illnName}.${extension}`)", alt="")
</template>

<script>
export default {
    name: 'ui-desktop-mobile-image',
    props: {
        imageName: {
            type: String,
            required: true,
        },
        mobileName: {
            type: String,
            default: 'mobile',
        },
        desktopName: {
            type: String,
            default: 'desktop',
        },
        extension: {
            type: String,
            default: 'svg',
        },
    },
    computed: {
        illnName() {
            return this.$platform.isMobile ? this.mobileName : this.desktopName;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-desktop-mobile-image {
    width: 100%;

    img {
        width: 100%;
        object-fit: contain;
    }

    @media (--viewport-tablet) {
        width: 100%;
        padding: 0 var(--offset-x);
    }
}
</style>
